import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { Helmet } from "react-helmet"
import Cookies from "universal-cookie"

import Layout from "../../components/SnickerHungerInsurenceComponents/components/layout"
import Header from "../../components/SnickerHungerInsurenceComponents/components/header/header"
import Footer from "../../components/SnickerHungerInsurenceComponents/components/footer/footer"
import HomeContent from "../../components/SnickerHungerInsurenceComponents/components/home-content/homeContent"
import HomeTabs from "../../components/SnickerHungerInsurenceComponents/components/home-tabs/homeTabs"
import Claim from "../../components/SnickerHungerInsurenceComponents/components/claim/claim"
import ChatModal from "../../components/SnickerHungerInsurenceComponents/components/Modals/chat-modal"

const IndexPage = () => {
  const [popUp, setPopUp] = useState(false)
  let ageCookie = new Cookies()
  useEffect(() => {
    if (!ageCookie.get("isOldEnough")) {
      setPopUp(false)
      navigate("/snickers-hunger-insurance/agegate")
    } else {
      setPopUp(true)
    }
  }, [])
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="../../SnickersHungerInsurance/css/app.css"
        />
        <link
          rel="stylesheet"
          href="../../SnickersHungerInsurance/css/responsive.css"
        />
      </Helmet>
      <Layout>
        <Header />
        <section className="main-section bg-primary mb-4 mb-md-5">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-6">
                <HomeContent />
              </div>
              <div className="col-lg-6 bg-secondary position-relative px-0 main-tabs">
                <HomeTabs />
              </div>
            </div>
          </div>
        </section>

        <Claim />
        <div className="mb-4">
          <Footer />
        </div>

        {/* <df-messenger
          intent="WELCOME"
          chat-title="SnickersInsuranceChatbot"
          agent-id="b82be01c-2c46-40b8-b515-b428454db79a"
          language-code="en"
        ></df-messenger> */}
      </Layout>
      <ChatModal show={popUp} onHide={() => setPopUp(false)} animation={true} />
    </>
  )
}

export default IndexPage
