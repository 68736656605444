import React from "react"

import iconComment from "../../images/icon-comment.png"
import profilePhoto from "../../images/profile-photo.png"

const ClaimButton = () => {
  // let chatBtn
  // setTimeout(() => {
  //   chatBtn = document.querySelector(".zsiq_floatmain")
  // }, 2000)

  // const openChatHandler = () => {
  //   chatBtn.click()
  // }
  return (
    <>
      <div
        className="btn btn-claim"
        id="btn-claim"
        // onClick={openChatHandler}
        // onKeyDown={openChatHandler}
        role="button"
        tabIndex={0}
      >
        <figure className="mb-0 icon-comment">
          {/* <StaticImage
            src="../../images/icon-comment.png"
            alt="Icon Comment"
            quality={100}
          /> */}
          <img src={iconComment} alt="Icon Comment" className="img-fluid" />
        </figure>
        Start a Claim
        <figure className="mb-0 profile-photo">
          {/* <StaticImage
            src="../../images/profile-photo.png"
            alt="Profile Photo"
            quality={100}
          /> */}
          <img src={profilePhoto} alt="Icon Profile" className="img-fluid" />
        </figure>
      </div>
    </>
  )
}

export default ClaimButton
