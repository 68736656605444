/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  return (
    <>
      {/* <Helmet>
        <link rel="stylesheet" href="../SnickersHungerInsurance/css/app.css" />
        <link
          rel="stylesheet"
          href="../SnickersHungerInsurance/css/responsive.css"
        />
      </Helmet> */}
      {children}
    </>
  )
}

export default Layout
