import React from "react"

import { Modal } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import Claim from "../ModalClaim/claim"
import SnickersHungerInsurance from "../../images/snickers-hunger-insurance.png"

function ChatModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="align-items-start">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-center"
        >
          <figure className="ps-md-4 ps-2 mb-0">
            {/* <StaticImage
              className="modal-logo"
              src="../../images/snickers-hunger-insurance.png"
              alt="Snickers Logo"
              quality={100}
            /> */}
            <img
              src={SnickersHungerInsurance}
              alt="Snickers Logo"
              className="modal-logo"
            />
          </figure>
          <figure className="modal-fig-profile mb-0 position-absolute">
            <StaticImage
              className="modal-profile"
              src="../../images/profile-photo.png"
              alt="Profile Photo"
              quality={100}
            />
          </figure>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h1 className="modal-text text-center text-uppercase text-white mt-3 mb-0">
          START A CLAIM
        </h1>
        <h2 className="modal-text text-center text-uppercase text-white m-0">
          TO EARN YOUR SNICKERS
        </h2>
        <div className="d-flex justify-content-center py-md-4 py-3">
          <Claim />
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="justify-content-center pt-0 pb-md-4 pb-3">
        <Claim />
      </Modal.Footer> */}
    </Modal>
  )
}

export default ChatModal
