import React from "react"
import iconArrowRight from "../../images/icon-arrow-right.png"

const ChatButton = ({ innerText }) => {
  // let chatBtn
  // setTimeout(() => {
  //   chatBtn = document.querySelector(".zsiq_floatmain")
  // }, 2000)

  // const openChatHandler = () => {
  //   chatBtn.click()
  // }
  return (
    <>
      <div
        className="d-inline-flex justify-content-center align-items-center"
        // onClick={openChatHandler}
        // onKeyDown={openChatHandler}
        role="button"
        tabIndex={0}
      >
        {innerText}
        <img
          className="ms-2 icon-arrow-right"
          src={iconArrowRight}
          alt="Icon Arrow Right"
        />
      </div>
    </>
  )
}

export default ChatButton
