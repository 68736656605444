import React from "react"
import Logo from "./logo"
import LanguageSwitcher from "./languageSwitcher"

const Header = () => {
  return (
    <>
      <header className="main-header bg-white w-100">
        <div className="container position-relative">
          <Logo />
          {/* <LanguageSwitcher /> */}
        </div>
      </header>
    </>
  )
}
export default Header
