import * as React from "react"
import FooterNav from "./footerNav"
import Copyright from "./copyright"

const Footer = () => (
  <>
    <footer className="main-footer d-block bg-transparent">
      <div className="container">
        <FooterNav />
        <Copyright />
      </div>
    </footer>
  </>
)

export default Footer
